<template>
  <Modal :value="visible"
         :title="planId ? '批量修改' + title : '批量新增' + title"
         @on-visible-change="onChangeVisible"
         width="900"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业计划：</p>
          <Input v-model="workPlan"
                 placeholder="作业计划"
                 maxlength="30"
                 v-stringLimit />
        </li>
        <li class="form-ul-li a-center">
          <p class="li-title"></p>
          <Icon custom="i-icon icon-tishi1"
                size="16"
                color="#FF9B77"
                class="m-r-5"></Icon>
          此处作业计划将作为排班名称的前缀使用，例：作业计划-网格名称
        </li>
        <li class="form-ul-li person-input">
          <p class="li-title"><span class="li-must">*</span>网格名称：</p>
          <MyTreeSelect ref="gridTreeSelect"
                        :data="chooseList"
                        search
                        checked
                        style="width: 100%"
                        @onCheckChange="onChangeGridName">
            <div class="person">
              <div class="person-item"
                   v-for="(item, index) in gridList"
                   :key="index"
                   @click.stop="">
                <span class="line1">{{ item.name }}</span>
                <Icon custom="i-icon icon-guanbi"
                      size="16"
                      color="#6a6b75"
                      @click.stop="gridList.splice(index, 1)" />
              </div>
            </div>
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentName"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        style="width:100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li person-input">
          <p class="li-title"><span class="li-must">*</span>人员：</p>
          <MyTreeSelect ref="personTreeSelect"
                        :data="personTreeData"
                        search
                        checked
                        style="width: 100%"
                        @onCheckChange="onChangePerson">
            <div class="person">
              <div class="person-item"
                   v-for="(item, index) in personList"
                   :key="index"
                   @click.stop="">
                <span class="line1">{{ item.name }}</span>
                <Icon custom="i-icon icon-guanbi"
                      size="16"
                      color="#6a6b75"
                      @click.stop="personList.splice(index, 1)" />
              </div>
            </div>
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业规则：</p>
          <Select v-model="workRule"
                  label-in-value
                  clearable>
            <Option v-for="item in ruleArr"
                    :value="item.workRuleId"
                    :key="item.workRuleId">{{ item.ruleName }}</Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title">排班类型：</p>
          <RadioGroup v-model="schedulingType">
            <Radio :label="1">
              <span>手动(手动按月排班)</span>
            </Radio>
            <!-- 暂时隐藏自动排班 -->
            <Radio :label="2">
              <span>自动(按规则排班)</span>
            </Radio>
          </RadioGroup>
        </li>
      </ul>
      <!-- 手动排班 -->
      <div v-show="schedulingType == '1'"
           class="manual">
        <div class="manual-clear">
          <p @click.stop="onClickManualClear">
            <Icon custom="i-icon icon-shanchu1"
                  size="16" />
            清空当月排班
          </p>
        </div>
        <el-calendar v-model="calendarData"
                     class="manual-calendar">
          <template slot="dateCell"
                    slot-scope="{ data }">
            <!-- 非当月 -->
            <div class="flex a-center j-center"
                 style="width: 100%; height: 100%"
                 v-if="data.type != 'current-month'">
              {{ data.day.split("-").slice(2).join("") }}
            </div>
            <!-- 当月 -->
            <div class="calendar-cont"
                 v-if="data.type == 'current-month'"
                 @click.stop="onClickDatePlan(getCurrentDate(data.day))">
              <div class="warning-icon"
                   v-if=" getCurrentDate(data.day) &&getCurrentDate(data.day).isWarning == 1">
                <Icon custom="i-icon icon-tishi1"
                      size="18"
                      color="red"></Icon>
              </div>
              <div class="calendar-cont-top">
                {{ data.day.split("-").slice(2).join("") }}
              </div>
              <div class="calendar-cont-bottom">
                <template v-if="getCurrentDate(data.day)">
                  <!-- 有排班 -->
                  <template v-if="
                      getCurrentDate(data.day).groupName &&
                      getCurrentDate(data.day).groupId
                    ">
                    <div class="calendar-cont-bottom-group">
                      <div class="group-item">
                        <div v-for="(items, indexs) in getCurrentDate(data.day)
                            .groupArr"
                             :key="indexs"
                             class="line1"
                             :title="getGroupMsg(getCurrentDate(data.day), items)">
                          {{ getGroupMsg(getCurrentDate(data.day), items) }}
                        </div>
                      </div>
                      <div class="group-btns">
                        <span class="line1"
                              :title="getCurrentDate(data.day).groupName">{{ getCurrentDate(data.day).groupName }}</span>
                        <p v-if="isBeyondDate(data.day)">
                          <Icon custom="i-icon icon-xiugai"
                                size="16"
                                color="#0079fe"
                                title="修改"
                                @click.stop="onClickManualSetGroup(data.day)" />
                          <!-- <Icon custom="i-icon icon-shijian"
                                size="16"
                                color="#0079fe"
                                title="休假"
                                @click.stop="onClickManualHoliday(data.day)" /> -->
                          <Icon custom="i-icon icon-shanchu1"
                                size="16"
                                color="#0079fe"
                                title="清空"
                                @click.stop="onClickManualDelete(data.day)" />
                        </p>
                      </div>
                    </div>
                  </template>
                  <!-- 休假 -->
                  <template v-if="!getCurrentDate(data.day).groupId">
                    <div class="calendar-cont-bottom-leave">
                      <Icon custom="i-icon icon-xiu"
                            size="26"
                            color="#0eb36e" />
                    </div>
                    <div class="calendar-cont-bottom-btns"
                         v-if="isBeyondDate(data.day)">
                      <Icon custom="i-icon icon-wuziqingkuang"
                            size="14"
                            title="复制昨天"
                            color="#6c6d6e"
                            @click.stop="onClickManualCopyYesterday(data.day)" />
                      <Icon custom="i-icon icon-tianjia1"
                            size="14"
                            title="添加班组"
                            color="#6c6d6e"
                            @click="onClickManualSetGroup(data.day)" />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <!-- 无排班 -->
                  <div class="calendar-cont-bottom-btns"
                       v-if="isBeyondDate(data.day)">
                    <Icon custom="i-icon icon-wuziqingkuang"
                          size="14"
                          title="复制昨天"
                          color="#6c6d6e"
                          @click.stop="onClickManualCopyYesterday(data.day)" />
                    <!-- <Icon custom="i-icon icon-shijian"
                          size="14"
                          color="#6c6d6e"
                          title="休假"
                          @click.stop="onClickManualHoliday(data.day)" /> -->
                    <Icon custom="i-icon icon-tianjia1"
                          size="14"
                          title="添加班组"
                          color="#6c6d6e"
                          @click="onClickManualSetGroup(data.day)" />
                  </div>
                </template>
              </div>
            </div>
          </template>
        </el-calendar>
      </div>
      <!-- 自动排班 -->
      <div class="automatic"
           v-show="schedulingType == '2'">
        <div class="automatic-title">
          <div class="automatic-title-left">
            <span class="automatic-title-left-span">倒班规律</span>
            <!-- <div class="automatic-title-left-empty"
                 @click="onClickAutomaticEmpty">
              <Icon custom="i-icon icon-shanchu1"
                    size="16"
                    color="#fe0000" />
              清空排班
            </div>
            <div class="automatic-title-left-add"
                 @click.stop="onClickAutomaticAddPeriod">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"
                    color="#1785fe" />
              添加周期
            </div> -->
          </div>
          <div class="automatic-title-right">
            查看排班说明>>
            <Poptip trigger="hover"
                    content="content"
                    placement="bottom-end">
              <Icon custom="i-icon icon-tishi1"
                    size="20"
                    color="#0079fe"
                    class="cursor-pointer" />
              <div slot="content"
                   class="tip">
                <Icon custom="i-icon icon-shanchu1"
                      size="16"
                      color="#6c6d6e" />
                <span>{{ schedulingExplain }}</span>
              </div>
            </Poptip>
          </div>
        </div>
        <div class="automatic-cont">
          <ul class="automatic-cont-plan">
            <li class="automatic-cont-plan-item"
                v-for="(item, index) in automaticList"
                :key="index">
              <!-- <div class="item-top">
                <Input prefix="md-create"
                       placeholder="填写倒班周期"
                       maxlength="30"
                       v-intLimit
                       clearable
                       class="item-top-input" />
              </div> -->
              <div class="item-bottom">
                <!-- 休假 -->
                <div class="item-bottom-leave"
                     v-show="item.leave">
                  <Icon custom="i-icon icon-xiu"
                        size="26"
                        color="#0eb36e" />
                </div>
                <!-- 有排班 -->
                <div class="item-bottom-group"
                     v-if="item.groupArr && item.groupArr.length != 0">
                  <div class="group-item">
                    <div v-for="(items, indexs) in item.groupArr"
                         :key="indexs"
                         class="line1"
                         :title="getGroupMsg({ groupType: item.groupType }, items)">
                      {{ getGroupMsg({ groupType: item.groupType }, items) }}
                    </div>
                  </div>
                  <div class="group-btns">
                    <span class="line1"
                          :title="item.groupName">{{
                      item.groupName
                    }}</span>
                    <p class="li-title">
                      <Icon custom="i-icon icon-xiugai"
                            size="16"
                            color="#0079fe"
                            title="修改"
                            @click.stop="onClickAutomaticSetGroup(index)" />
                      <!-- <Icon custom="i-icon icon-shijian"
                            size="16"
                            color="#0079fe"
                            title="休假"
                            @click.stop="onClickAutomaticLeave(index)" /> -->
                      <Icon custom="i-icon icon-shanchu1"
                            size="16"
                            color="#0079fe"
                            title="清空"
                            v-if="index != 0"
                            @click.stop="onClickAutomaticDeletePeriod(index)" />
                    </p>
                  </div>
                </div>
                <!-- 无排班 -->
                <div class="item-bottom-btns"
                     v-else>
                  <Icon custom="i-icon icon-tianjia1"
                        size="14"
                        title="添加班组"
                        color="#6c6d6e"
                        @click.stop="onClickAutomaticSetGroup(index)" />
                  <!-- <Icon custom="i-icon icon-shijian"
                        size="14"
                        color="#6c6d6e"
                        title="休假"
                        @click.stop="onClickAutomaticLeave(index)"
                        v-if="!item.leave" /> -->
                  <Icon custom="i-icon icon-shanchu1"
                        size="14"
                        title="删除周期"
                        color="#6c6d6e"
                        v-if="index != 0"
                        @click.stop="onClickAutomaticDeletePeriod(index)" />
                </div>
              </div>
            </li>
          </ul>
          <div class="flex a-center timeframe">
            <p class="li-title"><span class="p-must">*</span>有效时段：</p>
            <DatePicker type="date"
                        :value="startTime"
                        :options="dateOptions"
                        :editable="false"
                        placeholder=""
                        @on-change="onChangeStartTime"></DatePicker>
            <span class="timeframe-sign">~</span>
            <DatePicker type="date"
                        :value="endTime"
                        :options="dateOptions"
                        :editable="false"
                        placeholder=""
                        @on-change="onChangeEndTime"></DatePicker>
          </div>
        </div>
        <div class="automatic-footer">
          <Icon custom="i-icon icon-jingshi"
                size="15"
                color="red"
                class="m-r-5"
                style="line-height: 14px" />
          当前平台设置为{{ summerTime }}至{{ winterTime }}为夏季作息时间，{{
            winterTime
          }}至{{ summerTime }}为冬季作息时间
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

    <!-- 添加班组 -->
    <RoadCleanShiftGroupModal v-model="roadCleanShiftGroupVisible"
                              @onClickConfirm="onClickGroupConfirm"></RoadCleanShiftGroupModal>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  formatDate,
  getBetweenDateStr,
  getTomorrow,
  getCurrentMonthAll,
} from "@/utils/dateRangUtil";
import MyTreeSelect from "@/components/common/MyTreeSelect";
import RoadCleanShiftGroupModal from "@/components/product/admin/modal/work/RoadCleanShiftGroupModal";
export default {
  components: {
    MyTreeSelect,
    RoadCleanShiftGroupModal,
  },
  model: {
    prop: "visible",
    event: "onChange",
  },
  props: {
    title: {
      type: String,
      default: "排班",
    },
    visible: Boolean,
    planId: String,
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return false
          // return date && date.valueOf() < Date.now();
        },
      },
      loading: false,
      workPlan: "", // 作业计划
      gridName: "", //网格名称
      gridId: "", //网格名称
      gridList: [],
      workRule: "", // 作业规则
      schedulingType: 1, //排班类型
      personList: [], // 人员
      // 人员树列表
      departmentName: '',
      departmentId: '',
      personTreeData: [],
      ruleArr: [],
      calendarData: new Date(),
      // 自动排班的排班说明
      // schedulingExplain: '重复天数：选中班组过度到下一周期需要的天数（日固定班，清填写“1”）；班组：排班人员当天上下班时段；',
      schedulingExplain: "班组：排班人员当天上下班时段；",
      // 自动排班周期列表
      automaticList: [{}],
      // 自动排班的有效时段
      startTime: "",
      endTime: "",
      // 自动排班点击的排班周期
      automaticIndex: "",
      // 添加班组
      roadCleanShiftGroupVisible: false,
      // 手动排班的列表
      manualList: [],
      // 手动排班点击的日期
      manualDay: "",
      summerTime: "",
      winterTime: "",
    };
  },
  watch: {
    planId (newValue) {
      newValue && this.getDetail();
    },
    visible (newValue) {
      if (newValue) {
        this.calendarData = new Date();
        this.getRuleList();
        this.getTimeDetail();
      }
    }
  },
  computed: {
    ...mapGetters({
      // personTreeData: "getPersonTreeList",
      // gridNameTreeArr: "getGridTreeList",
      departmentTreeArr: 'getDepartmentTreeList',
    }),
	chooseList(){
		let res=this.getTreeArr(this.$store.getters.getGridTreeList)
		return res
	}
  },
  mounted () {
    this.init();
  },
  methods: {
    ...mapActions(["updatePersonTreeList", "updateGridTreeList", 'updateDepartmentTreeList',]),
    init () {
      this.updatePersonTreeList();
      this.updateGridTreeList();
      this.updateDepartmentTreeList()
      this.timeInit();
    },
	getTreeArr(arr){
		let num = 0;
		return this.fun(arr,num)
	},
	fun(arr,num){
		num++
		let list = arr.filter(item=>{
			let type = false;
			if(item.children){//判断是否还有子集
				item.children = this.fun(item.children,num)
			}
			if(item.children&&item.children.length>0){
				type = true
			}
			return item.type=='3'||item.type=='2'||item.type=='1'||num==1||type
		})
		return list
	},
    // 获取详情
    getDetail () {
      this.$http.getRoadPlanDetailNew({ planId: this.planId }).then((res) => {
        let data = res.result;
        let arr = [];
        for (let i = 0; i < data.employeeIdList.length; i++) {
          arr.push({
            id: data.employeeIdList[i],
            name: data.employeeNameList[i],
          });
        }
        this.personList = arr;
        this.workPlan = data.empPlanMasterVo.planName;
        this.gridName = data.empPlanMasterVo.gridName;
        this.gridId = data.empPlanMasterVo.gridId;
        this.workRule = data.empPlanMasterVo.workRuleId;
        this.manualList = data.empPlanDetailVoList.map((item) => {
          return {
            groupId: item.workGroupId,
            groupName: item.workGroupName,
            groupType: item.kaoQinType,
            groupArr: item.workKaoQinList,
            date: item.date,
            isWarning: item.isWarning,
          };
        });
        this.getPersonTreeList()
      }).catch((err) => {
        console.log(err);
      });
    },
    // 获取冬夏时间详情
    getTimeDetail () {
      this.$store.dispatch("getTimeCompleteDetail", 2).then((res) => {
        let summer = res.summerDate.split("-");
        this.summerTime = summer[0] + "月" + summer[1] + "日";
        let winter = res.winterDate.split("-");
        this.winterTime = winter[0] + "月" + winter[1] + "日";
      });
    },
    // 日期选择器赋初始值
    timeInit () {
      this.startTime = getTomorrow();
      this.endTime = getCurrentMonthAll()[1];
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: 1,
        size: 999,
        module: 2,
      };
      this.$http.getWorkRuleList(params).then((res) => {
        if (res.code === 200) {
          this.ruleArr = res.result.workRules;
        }
      });
    },
    // 选择网格
    onChangeGridName (section) {
      let dataArr = []
      section.forEach((item) => {
        if (item.type != "depart") {
          dataArr.push(item)
        }
      })
      dataArr.forEach(t => {
        let isFind = false;
        for (let i = 0; i < this.gridList.length; i++) {
          if (t.id == this.gridList[i].id) {
            isFind = true;
            break;
          }
        }
        if (!isFind) {
          this.gridList.push(t);
        }
      })
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
      this.personList = []
      // 获取人员列表
      this.getPersonTreeList()
    },
    // 获取人员列表
    getPersonTreeList () {
      let params = {
        depid: this.departmentId
      }
      this.$http.getPersonDetailTreeList(params).then(res => {
        if (res.code === 200) {
          this.personTreeData = res.result
        }
      })
    },
    // 选择人员
    onChangePerson (section) {
      let dataArr = []
      section.forEach((item) => {
        if (item.type === "user") {
          dataArr.push(item)
        }
      })
      dataArr.forEach(t => {
        let isFind = false;
        for (let i = 0; i < this.personList.length; i++) {
          if (t.id == this.personList[i].id) {
            isFind = true;
            break;
          }
        }
        if (!isFind) {
          this.personList.push(t);
        }
      })
    },
    // 人员单独修改过 触发
    onClickDatePlan (item) {
      if (!item || item.isWarning == 0) return;
      this.$Modal.info({
        title: "改动",
        content: "当日数据改动，请前往详情列表查看。",
      });
    },
    // 是否超出日期
    isBeyondDate (date) {
      return true
      // return formatDate(new Date()) <= date;
    },
    // 获取当前日期
    getCurrentDate (date) {
      return this.manualList.find((item) => item.date === date);
    },
    // 清空手动排班
    onClickManualClear () {
      this.$Modal.confirm({
        title: "清空",
        content: "确认清空当月排班?",
        onOk: () => {
          this.manualList = [];
        },
      });
    },
    // 手动排班复制昨天
    onClickManualCopyYesterday (date) {
      let newDate = new Date(date);
      newDate.setDate(newDate.getDate() - 1);
      let lastDateItem = this.getCurrentDate(formatDate(newDate));
      if (lastDateItem) {
        let newObj = JSON.parse(JSON.stringify(lastDateItem));
        newObj.date = date;
        let index = this.manualList.findIndex((item) => item.date === date);
        if (index !== -1) {
          this.manualList.splice(index, 1, newObj);
        } else {
          this.manualList.push(newObj);
        }
      } else {
        this.$Message.info("无可复制班次");
      }
    },
    // 手动排班休假
    onClickManualHoliday (date) {
      this.$Modal.confirm({
        title: "休假",
        content: "确认休假?",
        onOk: () => {
          let index = this.manualList.findIndex((item) => item.date === date);
          let newObj = {
            date,
            groupId: 0,
          };
          if (index !== -1) {
            this.manualList.splice(index, 1, newObj);
          } else {
            this.manualList.push(newObj);
          }
        },
      });
    },
    // 手动排班删除排班
    onClickManualDelete (date) {
      let index = this.manualList.findIndex((item) => item.date === date);
      this.manualList.splice(index, 1);
    },
    // 手动排班的设置班组
    onClickManualSetGroup (date) {
      this.manualDay = date;
      this.roadCleanShiftGroupVisible = true;
    },
    // 添加班组 确认回调
    onClickGroupConfirm (group) {
      if (this.schedulingType == 1) {
        let index = this.manualList.findIndex(
          (item) => item.date === this.manualDay
        );
        let newObj = {
          date: this.manualDay,
          groupId: group.id,
          groupName: group.name,
          groupArr: group.kaoqins,
          groupType: group.kaoqinType,
        };
        if (index !== -1) {
          this.manualList.splice(index, 1, newObj);
        } else {
          this.manualList.push(newObj);
        }
      } else {
        this.automaticList[this.automaticIndex] = {
          groupName: group.name,
          groupType: group.kaoqinType,
          groupArr: group.kaoqins,
          groupId: group.id,
        };
      }
    },
    // 获取班组信息
    getGroupMsg (item, items) {
      if (item.groupType === 1) {
        return `${items.name}(${items.sbsj}~${items.xbsj})`;
      } else {
        return `${items.name}(夏 ${items.xsbsj}~${items.xxbsj})(冬 ${items.dsbsj}~${items.dxbsj})`;
      }
    },
    // 自动排班的清空周期
    onClickAutomaticEmpty () {
      this.automaticList = [{}];
    },
    // 自动排班的添加周期
    onClickAutomaticAddPeriod () {
      this.automaticList.push({});
    },
    // 自动排班的删除周期
    onClickAutomaticDeletePeriod (index) {
      this.$Modal.confirm({
        title: "删除",
        content: "删除此倒班周期",
        onOk: () => {
          this.automaticList.splice(index, 1);
        },
      });
    },
    // 自动排班的休假
    onClickAutomaticLeave (index) {
      this.$Modal.confirm({
        title: "休假",
        content: "确认休假?",
        onOk: () => {
          this.automaticList.splice(index, 1, { leave: true });
        },
      });
    },
    // 自动排班的增加班组
    onClickAutomaticSetGroup (index) {
      this.roadCleanShiftGroupVisible = true;
      this.automaticIndex = index;
    },
    // 自动排班有效期开始
    onChangeStartTime (date) {
      this.startTime = date;
    },
    // 自动排班有效期结束
    onChangeEndTime (date) {
      this.endTime = date;
    },
    onClickConfirm () {
      if (!this.workPlan) return this.$Message.info("请输入作业计划");
      if (!this.gridList.length) return this.$Message.info("请选择网格");
      if (!this.personList.length) return this.$Message.info("请选择人员");
      this.loading = true
      let gridIds = []
      this.gridList.forEach((item) => {
        gridIds.push(item.id)
      })
      let repeatName = {
        planName: this.workPlan,
        gridList: gridIds,
        module: 1
      }
      if (this.planId) {
        this.createPlan()
      } else {
        this.$http.repeatPersonPlanName(repeatName).then((res) => {
          if (res.result.length) {
            this.loading = false
            this.$Message.info({
              content: '作业计划名称重复'
            })
          } else {
            // 创建
            this.createPlan()
          }
        })
      }
    },
    createPlan () {
      // 排班日历
      let manualList;
      if (this.schedulingType == 2) {
        if (!this.automaticList[0].groupId) return this.$Message.info("请进行排班");
        let arr = [];
        arr = getBetweenDateStr(this.startTime, this.endTime).map((item) => {
          return {
            date: item,
            workGroupId: this.automaticList[0].groupId,
          };
        });
        manualList = arr;
      } else {
        manualList = this.manualList.map((item) => {
          return {
            date: item.date,
            workGroupId: item.groupId,
          }
        })
        if (!manualList.length) return this.$Message.info("请进行排班")
      }
      // 人员id
      let personIds = []
      this.personList.forEach((item) => {
        personIds.push(item.id)
      })
      let gridIds = []
      this.gridList.forEach((item) => {
        gridIds.push(item.id)
      })
      let params = {
        planName: this.workPlan,
        employeeList: personIds,
        workRuleId: this.workRule,
        empPlanDateToList: manualList,
        departmentId: this.departmentId,
      }
      if (this.planId) {
        params.planId = this.planId
        this.$http.editRoadPlanNew(params).then((res) => {
          this.$emit("onClickConfirm", params)
          this.$emit("onChange", false)
          this.loading = false
        }).catch((err) => {
          this.loading = false
        });
      } else {
        params.module = 1
        params.gridList = gridIds
        this.$http.createRoadPlanNew(params).then((res) => {
          this.$emit("onClickConfirm", params)
          this.$emit("onChange", false)
          this.loading = false
        }).catch((err) => {
          this.loading = false
        })
      }
    },
    onClickCancel () {
      this.$emit("onClickCancel")
      this.$emit("onChange", false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData();
        this.$emit("onChange", false)
        this.$emit("update:planId", "")
      }
    },
    resetData () {
      this.workPlan = ""
      this.gridName = ""
      this.workRule = ""
      this.schedulingType = 1
      this.personList = []
      this.gridList = []
      this.departmentId = ''
      this.departmentName = ''
      this.manualList = []
      this.automaticList = [{}]
      this.automaticIndex = ""
      this.$refs.personTreeSelect.resetTreeData()
      this.$refs.gridTreeSelect.resetTreeData()
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep {
  .el-calendar-table {
    .el-calendar-day {
      height: 140px;
      padding: 8px 0 0;
    }
  }
}
.person-input {
  .relative {
    width: 100%;
    ::v-deep {
      // .ivu-input {
      //   height: 100px;
      // }
      // .treeSelect-dropdown {
      //   .ivu-input {
      //     height: 32px;
      //   }
      // }
      .ivu-tree-title {
        line-height: 32px;
      }
    }
  }
}

.person {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 7px;
  cursor: pointer;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .person-item {
    width: 85px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px 6px 0;
    padding-left: 4px;
    border: 1px solid #e1e4e6;
    border-radius: 3px;
  }
}
// 手动排班
.manual {
  .manual-clear {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    p {
      padding: 0 12px;
      height: 24px;
      border: 1px solid #fe0000;
      color: #fe0000;
      border-radius: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  .manual-calendar {
    .calendar-cont {
      position: relative;
      .warning-icon {
        position: absolute;
        top: -5px;
        right: 5px;
      }
      .calendar-cont-top {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .calendar-cont-bottom {
        display: flex;
        flex-direction: column;
        min-height: 110px;
        padding-top: 6px;
        .calendar-cont-bottom-leave {
          text-align: center;
        }
        .calendar-cont-bottom-group {
          display: flex;
          flex-direction: column;
          flex: 1;
          color: #444;
          font-size: 12px;
          .group-item {
            flex: 1;
            div {
              // width: 90%;
              margin: 0 0px 4px;
              line-height: 22px;
            }
          }
          .group-btns {
            height: 30px;
            line-height: 30px;
            background-color: #e6e8f0;
            color: #0079fe;
            display: flex;
            justify-content: space-around;
            span {
              width: 60px;
            }
            p {
              i {
                margin-right: 2px;
                cursor: pointer;
              }
            }
          }
        }
        .calendar-cont-bottom-btns {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -50%;
          color: #444;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            width: 20px;
            height: 20px;
            background-color: #eee;
            border-radius: 50%;
            border: 1px solid #ddd;
            cursor: pointer;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          i:hover {
            background: #ddd;
          }
          i:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
// 自动排班
.automatic {
  .automatic-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    // border-bottom: 1px solid #ddd;
    background-color: #f5f8f9;
    margin-bottom: 10px;
    padding-left: 10px;
    .automatic-title-left {
      display: flex;
      .automatic-title-left-span {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
        border-left: 2px solid #25bb96;
      }
      .automatic-title-left-empty,
      .automatic-title-left-add {
        box-sizing: border-box;
        padding: 0 12px;
        height: 26px;
        line-height: 24px;
        margin-left: 14px;
        border: 1px solid #fe0000;
        color: #fe0000;
        border-radius: 14px;
        cursor: pointer;
        font-size: 12px;
      }
      .automatic-title-left-add {
        border: 1px solid #0079fe;
        color: #1785fe;
      }
    }
    .automatic-title-right {
      color: #0079fe;
      .tip {
        display: flex;
        align-items: flex-start;
        color: #6c6d6e;
        span {
          margin-left: 4px;
        }
      }
    }
  }
  .automatic-cont {
    .automatic-cont-plan {
      display: flex;
      flex-wrap: wrap;
      .automatic-cont-plan-item {
        width: 14%;
        padding-bottom: 0;
        border: 1px solid #ddd;
        border-right: none;
        position: relative;
        .item-top {
          display: flex;
          align-items: center;
          padding: 5px 5px 0;
          .item-top-input {
            ::v-deep {
              .ivu-input {
                border: none;
                padding-right: 0;
                padding-left: 20px;
                text-align: center;
              }
              .ivu-input-prefix {
                width: 22px;
              }
            }
          }
        }
        .item-bottom {
          display: flex;
          flex-direction: column;
          min-height: 106px;
          padding-top: 6px;
          .item-bottom-leave {
            text-align: center;
          }
          .item-bottom-group {
            display: flex;
            flex-direction: column;
            height: 100px;
            color: #444;
            font-size: 12px;
            .group-item {
              flex: 1;
              div {
                // width: 90%;
                margin: 0 0px 4px;
                line-height: 22px;
                word-break: break-all;
              }
            }
            .group-btns {
              height: 30px;
              line-height: 30px;
              background-color: #e6e8f0;
              color: #0079fe;
              display: flex;
              justify-content: space-around;
              span {
                width: 60px;
              }
              p {
                i {
                  margin-right: 2px;
                  cursor: pointer;
                }
              }
            }
          }
          .item-bottom-btns {
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -50%;
            color: #444;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              width: 20px;
              height: 20px;
              background-color: #eee;
              border-radius: 50%;
              border: 1px solid #ddd;
              cursor: pointer;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            i:hover {
              background: #ddd;
            }
            i:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .automatic-cont-plan-item:nth-child(7n) {
        border-right: 1px solid #ddd;
      }
      .automatic-cont-plan-item:nth-child(7) ~ li {
        border-top: 0;
      }
    }
    .timeframe {
      width: 50%;
      margin-top: 20px;
      p {
        line-height: 32px;
        min-width: 100px;
        text-align: right;
        .p-must {
          color: #ff0000;
          font-size: 16px;
          margin-right: 2px;
        }
      }

      .timeframe-sign {
        margin: 0 5px;
      }
    }
  }
  .automatic-footer {
    margin-top: 20px;
    padding-left: 20px;
    line-height: 40px;
    font-size: 12px;
    color: #6c6d6e;
    background-color: #fafbfc;
    display: flex;
    align-items: center;
  }
}
</style>