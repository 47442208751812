<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="title"
         width="700"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>请假时间：</p>
          <Input v-model="time"
                 placeholder="请假时间"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>请假类型：</p>
          <Select v-model="type"
                  placeholder="请假类型">
            <Option :value="item.id"
                    v-for="item in typeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '选择请假时间'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      time: '',
      type: '',
      typeArr: [
        {
          id: 1,
          name: '事假'
        },
        {
          id: 2,
          name: '年假'
        },
        {
          id: 3,
          name: '调休假'
        },
        {
          id: 4,
          name: '其他'
        },
      ]
    };
  },
  watch: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    onClickConfirm () {
      if (!this.time) return this.$Message.info('请输入请假时间')
      if (!this.type) return this.$Message.info('请选择请假类型')

    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.time = ''
      this.type = ''
    }
  },
}
</script>

<style lang='scss' scoped>
</style>