<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         footer-hide
         :title="title"
         width="700"
         class-name="vertical-center-modal">
    <div>
      <div class="shift-tip"
           v-if="data">
        <Icon custom="i-icon icon-tishi1"
              size="16"
              color="#ff9b77"
              class="m-r-5" />
        <span class="line1">{{data.person}},{{data.date}},{{data.text}}</span>
      </div>
      <div class="shift-btns">
        <!-- <Button type="text"
                @click.stop="roadCleanReplaceJobVisible = true">
          <Icon custom="i-icon icon-renyuanjiaohu"
                size="16"
                class="m-r-5" />
          <span>顶岗</span>
        </Button> -->
        <Button type="text"
                @click.stop="onClickEditGroup">
          <Icon custom="i-icon icon-xiugai1"
                size="16"
                class="m-r-5" />
          <span>修改当天班次</span>
        </Button>
        <!-- <Button type="text"
                @click.stop="onClickHoliday">
          <Icon custom="i-icon icon-shijian"
                size="16"
                class="m-r-5" />
          <span>休假</span>
        </Button> -->
        <!-- <Button type="text"
                @click.stop="roadCleanLeaveVisible = true">
          <Icon custom="i-icon icon-qingjia"
                size="16"
                class="m-r-5" />
          <span>请假</span>
        </Button> -->
      </div>
    </div>

    <!-- 请假 -->
    <RoadCleanLeaveModal v-model="roadCleanLeaveVisible"
                         :dataId="roadCleanLeaveId"></RoadCleanLeaveModal>

    <!-- 顶岗 -->
    <RoadCleanReplaceJobModal v-model="roadCleanReplaceJobVisible"
                              :dataId="roadCleanReplaceJobId"></RoadCleanReplaceJobModal>
  </Modal>
</template>

<script>
import RoadCleanShiftGroupModal from '@/components/product/admin/modal/work/RoadCleanShiftGroupModal'
import RoadCleanLeaveModal from '@/components/product/admin/modal/work/RoadCleanLeaveModal'
import RoadCleanReplaceJobModal from '@/components/product/admin/modal/work/RoadCleanReplaceJobModal'
export default {
  components: {
    RoadCleanShiftGroupModal,
    RoadCleanLeaveModal,
    RoadCleanReplaceJobModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '修改排班111'
    },
    data: Object
  },
  data () {
    return {
      // 修改班组
      roadCleanShiftGroupVisible: false,
      roadCleanShiftGroupId: '',
      // 请假
      roadCleanLeaveVisible: false,
      roadCleanLeaveId: '',
      // 顶岗
      roadCleanReplaceJobVisible: false,
      roadCleanReplaceJobId: this.dataId
    };
  },
  watch: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    onClickEditGroup () {
      this.$emit('onClickEditGroup')
      this.$emit('onChange', false)
    },
    // 休假
    onClickHoliday () {
      this.$emit('onClickHoliday')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.shift-tip {
  line-height: 40px;
  padding: 0 18px;
  margin-bottom: 16px;
  font-size: 12px;
  color: #6c6d6e;
  background-color: #fafbfc;
  display: flex;
  align-items: center;
}
.shift-btns {
  color: #25bb96;
  display: flex;
  button {
    flex: 1;
  }
}
</style>