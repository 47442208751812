<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="700"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>班组名称：</p>
          <MyTreeSelect :value="name"
                        placeholder="班组名称"
                        clearable
                        :data="nameArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeName">
          </MyTreeSelect>
        </li>
        <!-- <li class="form-ul-li">
          <p class="li-title">自动填充班组：</p>
          <Checkbox v-model="autoFill">勾选后,系统会自动填充当天及以后的为输入框中班组</Checkbox>
        </li> -->
      </ul>
      <div class="shift-tip"
           v-if="name">
        <Icon custom="i-icon icon-tishi1"
              size="16"
              color="#ff9b77"
              class="m-r-5" />
        <span class="line1">{{name}}</span>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '班组'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      autoFill: false,
      nameArr: [],
      group: {}
    };
  },
  watch: {
    visible (newValue) {
      newValue && this.getGroupList()
    }
  },
  computed: {
    ...mapGetters({
      // 'nameArr': 'getWorkGroupTreeList'
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      // 'updateWorkGroupTreeList'
    ]),
    init () {
      // this.updateWorkGroupTreeList()
    },
    // 获取班组列表
    getGroupList () {
      this.$http.getWorkGroupItemAllList({ module: 2 }).then(res => {
        if (res.code === 200) {
          this.nameArr = res.result.map(item => {
            item.name = item.depName
            item.id = item.depid
            item.type = 'depart'
            if (item.children) {
              item.children = item.children.map(items => {
                items.id = items.workGroupId
                items.type = 'group'
                return items
              })
            }
            return item
          })
        }
      })
    },
    // 选择班组
    onChangeName (data) {
      if (data.type == 'group') {
        this.name = data.name
        this.group = data
      }
    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请选择班组')
      this.$emit('onClickConfirm', this.group)
      this.$emit('onChange', false)
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
    }
  },
}
</script>

<style lang='scss' scoped>
.modal-cont {
  overflow-y: visible;
}
.shift-tip {
  line-height: 40px;
  padding: 0 18px;
  margin-bottom: 16px;
  font-size: 12px;
  color: #6c6d6e;
  background-color: #fafbfc;
  display: flex;
  align-items: center;
}
</style>