
// 道路保洁排班计划
<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex === 0">
              <!-- <Select v-model="planType"
                      clearable
                      placeholder="计划类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in planTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select> -->
              <!-- <Select v-model="workLevel"
                      clearable
                      placeholder="作业等级"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in workLevelArr"
                        :value="item.levelId"
                        :key="item.levelId">{{ item.name }}</Option>
              </Select> -->
              <Input v-model="planName"
                     placeholder="计划名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width: 160px" />
              <!-- <MyTreeSelect :value="name"
                            placeholder="姓名"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeName">
              </MyTreeSelect> -->
              <DatePicker type="month"
                          placeholder="选择月份"
                          :value="date"
                          @on-change="onChangeDate"
                          style="width: 160px"></DatePicker>
            </template>
            <template v-if="currentTabIndex === 1">
              <Select v-model="teamGroupType"
                      clearable
                      placeholder="班组类型"
                      class="m-r-10"
                      style="width: 160px">
                <Option v-for="item in teamGroupTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="teamGroupName"
                     placeholder="班组名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width: 160px" />
            </template>
            <template v-if="currentTabIndex === 2">
              <Input v-model="ruleName"
                     placeholder="规则名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width: 160px" />
            </template>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex === 0">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/roadClean-crewScheduling/query'])"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/roadClean-crewScheduling/query'])"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickNextMonth">下月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template v-if="currentTabIndex === 0">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/add'])"
                    @click.stop="roadCleanPlanVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加计划
            </Button>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/add'])"
                    @click.stop="gridVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加网格
            </Button>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/add'])"
                    @click.stop="roadCleanPlanBatchVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              批量添加计划
            </Button>
            <Dropdown v-if="checkPermi(['/admin/roadClean-crewScheduling/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            v-if="checkPermi(['/admin/roadClean-crewScheduling/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        action='/hss/empPlan/excelReader'
                        v-if="checkPermi(['/admin/roadClean-crewScheduling/import'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <!-- <Dropdown @on-click="onClickBatch">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="edit">批量修改</DropdownItem>
                <DropdownItem name="recover">批量启用</DropdownItem>
                <DropdownItem name="disable">批量禁用</DropdownItem>
                <DropdownItem name="delete">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown> -->
            <!-- <Button type="primary"
                    class="m-r-10"
                    @click.stop="onDeleteClear(selectArr)">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              批量删除
            </Button> -->
            <!-- <Dropdown>
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button> 导出本页 </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button> 导出全部 </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate> -->
            <!-- <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        :action='getReaderFileAction'>
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile> -->
          </div>
        </template>
        <template v-if="currentTabIndex === 1">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/add'])"
                    @click.stop="workGroupVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
        <template v-if="currentTabIndex === 2">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/roadClean-crewScheduling/add'])"
                    @click.stop="workRuleVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
        <template v-if="currentTabIndex === 0 || currentTabIndex === 1">
          <div class="flex a-center">
            <Icon custom="i-icon icon-tishi1"
                  size="16"
                  color="#FF9B77"></Icon>
            当前平台设置为{{ summerTime }}至{{ winterTime }}为夏季作息时间，{{winterTime}}为冬季作息时间
            <Button type="text"
                    class="m-l-5"
                    @click.stop="onClickTime">设置冬夏时间 >></Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{ height: tableHeight + 50 + 'px' }"
             v-if="currentTabIndex === 0">
          <MyTree :data="departmentTreeArr"
                  style="padding: 0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex === 0">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/roadClean-crewScheduling/edit']"
                        @click.stop="onClickEditPlan(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/roadClean-crewScheduling/delete']"
                        @click.stop="onClickClearPlan(row)">清空</Button>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex === 1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/roadClean-crewScheduling/edit']"
                          @click.stop="onClickEditGroup(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/roadClean-crewScheduling/delete']"
                          @click.stop="onClickDeleteGroup(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex === 2">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/roadClean-crewScheduling/edit']"
                          @click.stop="onClickEditRule(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/roadClean-crewScheduling/edit']"
                          @click.stop="onClickStatusRule(row)">{{ row.isEnable == 1 ? "禁用" : "恢复" }}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/roadClean-crewScheduling/delete']"
                          @click.stop="onClickDeleteRule(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <!-- 添加计划 -->
    <RoadCleanPlanModal v-model="roadCleanPlanVisible"
                        :planId.sync="roadCleanPlanDataPlanId"
                        @onClickConfirm="getList"
                        @onChange='onChangClearRowStyle'></RoadCleanPlanModal>

    <!-- 批量添加计划 -->
    <RoadCleanPlanBatchModal v-model="roadCleanPlanBatchVisible"
                             :planId.sync="roadCleanPlanDataBatchPlanId"
                             @onClickConfirm="getList"
                             @onChange='onChangClearRowStyle'></RoadCleanPlanBatchModal>

    <!-- 设置冬夏时间 -->
    <WorkTimeModal v-model="workTimeVisible"
                   :dataId.sync="workTimeId"
                   @onClickConfirm="getTimeDetail"></WorkTimeModal>

    <!-- 表格中的绑定规则 -->
    <TableWorkRuleModal v-model="tableWorkRuleVisible"
                        :data.sync="tableWorkRuleData"
                        type="2"
                        @onChange='onChangClearRowStyle'></TableWorkRuleModal>

    <!-- 修改排班 -->
    <RoadCleanShiftModal v-model="roadCleanShiftVisible"
                         :data="tableClickDay"
                         @onClickEditGroup="onClickPlanEditGroup"
                         @onClickHoliday="onClickPlanEditHoliday"
                         @onChange='onChangClearRowStyle'></RoadCleanShiftModal>

    <!-- 新增 / 修改作业班组 -->
    <WorkGroupModal v-model="workGroupVisible"
                    :dataId.sync="workGroupId"
                    :ruleType="2"
                    @onClickConfirm="getList"
                    @onChange='onChangClearRowStyle'></WorkGroupModal>

    <!-- 作业规则 -->
    <WorkRuleModal v-model="workRuleVisible"
                   :dataId.sync="workRuleId"
                   type="2"
                   @onClickConfirm="getList"
                   @onChange='onChangClearRowStyle'></WorkRuleModal>

    <!-- 添加班组 -->
    <RoadCleanShiftGroupModal v-model="roadCleanShiftGroupVisible"
                              @onClickConfirm="onClickGroupConfirm"></RoadCleanShiftGroupModal>

    <!-- 新增 / 修改网格 -->
    <GridModal v-model="gridVisible"
               :width="drawerWidth"
               @onChange='onChangClearRowStyle'></GridModal>

    <!-- 班组详情列表 -->
    <RoadCleanPlanDetailModal v-model="roadCleanPlanDetailVisible"
                              :dataId.sync="roadCleanPlanDetailId"
                              :title="roadCleanPlanDetailTitle"
                              :date="roadCleanPlanDetailDate"
                              :width="drawerWidth"
                              @onClickCancel="getList"
                              @onChange='onChangClearRowStyle'></RoadCleanPlanDetailModal>
    <!-- 批量修改 -->
    <!-- <RoadCleanPlanBatchsModal v-model="roadCleanPlanBatchsVisible"
                                   :dataId.sync="roadCleanPlanBatchsId"
                                   @onClickConfirm="getList"></RoadCleanPlanBatchsModal> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getCurrentMonth,
  getNextMonth,
  getMonthDays,
  getYearOrMonthForMonth
} from "@/utils/dateRangUtil";
import MyTreeSelect from "@/components/common/MyTreeSelect";
import MyTree from "@/components/common/MyTree";
import RoadCleanPlanModal from "@/components/product/admin/modal/work/RoadCleanPlanModal";
import RoadCleanPlanBatchModal from "@/components/product/admin/modal/work/RoadCleanPlanBatchModal";
import WorkTimeModal from "@/components/product/admin/modal/work/WorkTimeModal";
import TableWorkRuleModal from "@/components/product/admin/modal/work/TableWorkRuleModal";
import RoadCleanShiftModal from "@/components/product/admin/modal/work/RoadCleanShiftModal";
import WorkGroupModal from "@/components/product/admin/modal/work/WorkGroupModal";
import WorkRuleModal from "@/components/product/admin/modal/work/WorkRuleModal";
import RoadCleanShiftGroupModal from "@/components/product/admin/modal/work/RoadCleanShiftGroupModal";
import GridModal from "@/components/product/admin/modal/work/GridModal";
import RoadCleanPlanDetailModal from "@/components/product/admin/modal/work/RoadCleanPlanDetailModal";
import ExportTemplate from "@/components/common/ExportTemplate";
import ReaderFile from "@/components/common/ReaderFile";
import { checkPermi } from '@/utils/permission'
// import RoadCleanPlanBatchsModal from "@/components/product/admin/modal/work/RoadCleanPlanBatchsModal";
export default {
  components: {
    MyTreeSelect,
    MyTree,
    RoadCleanPlanModal,
    RoadCleanPlanBatchModal,
    WorkTimeModal,
    TableWorkRuleModal,
    RoadCleanShiftModal,
    WorkGroupModal,
    WorkRuleModal,
    RoadCleanShiftGroupModal,
    GridModal,
    RoadCleanPlanDetailModal,
    ExportTemplate,
    ReaderFile,
    // RoadCleanPlanBatchsModal
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: "排班计划",
        },
        {
          name: "作业班组",
        },
        {
          name: "作业规则",
        },
      ],
      planType: "",
      planTypeArr: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "手动",
        },
        {
          id: 2,
          name: "自动",
        },
      ],
      workLevel: "",
      planName: "",
      nameId: "",
      name: "",
      teamGroupType: "",
      departId: "",
      teamGroupTypeArr: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "固定班",
        },
        {
          id: 2,
          name: "冬夏班",
        },
      ],
      teamGroupName: "",
      ruleName: "",
      date: "",
      summerTime: "",
      winterTime: "",
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableWidths: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsPlan: [
        {
          type: "selection",
          width: 45,
          align: "center",
          fixed: 'left',
        },
        {
          title: "序号",
          width: 45,
          align: "center",
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "计划名称",
          key: "planName",
          // width: 110,
          tooltip: true,
          align: "center",
          fixed: 'left',
          render: (h, params) => {
            let row = params.row;
            return h(
              "span",
              {
                style: {
                  color: "#25bb96",
                  cursor: "pointer",
                },
                class: {
                  line1: true,
                },
                on: {
                  click: () => {
                    this.onClickPlanDetailList(row);
                  },
                },
              },
              row.planName
            );
          },
        },
        {
          title: "网格名称",
          key: "gridName",
          // width: 110,
          tooltip: true,
          // fixed: 'left',
          align: "center",
        },
        {
          title: "作业规则",
          key: "workRuleName",
          // width: 110,
          tooltip: true,
          // fixed: 'left',
          align: "center",
          render: (h, params) => {
            let row = params.row;
            return h(
              "span",
              {
                style: {
                  color: "#25bb96",
                  cursor: "pointer",
                },
                class: {
                  line1: true,
                },
                on: {
                  click: () => {
                    this.onClickPlanRule(row);
                  },
                },
              },
              row.workRuleName ? row.workRuleName : "点击绑定"
            );
          },
        },
        // {
        //   title: '姓名',
        //   key: 'personName',
        //   width: 70,
        //   tooltip: true,
        //   fixed: 'left',
        //   align: 'center'
        // },
        // {
        //   title: '所属部门',
        //   key: 'depName',
        //   width: 110,
        //   tooltip: true,
        //   fixed: 'left',
        //   align: 'center'
        // },
        {
          title: "操作",
          slot: "operation",
          width: 120,
          // fixed: 'right',
          align: "center",
        },
      ],
      columnsTeamGroup: [
        {
          title: "序号",
          width: 45,
          align: "center",
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "班组名称",
          key: "name",
          tooltip: true,
          width: 80,
          align: "center",
          fixed: 'left',
        },
        {
          title: "所属机构",
          key: "depName",
          tooltip: true,
          width: 80,
          align: "center",
        },
        {
          title: "作业时间段",
          tooltip: true,
          minWidth: 80,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            let str = "";
            if (row.kaoqins) {
              row.kaoqins.forEach((item, index) => {
                str = str + item.name + " ";
                if (item.sbsj && item.xbsj) {
                  str = str + "【" + item.sbsj + "~" + item.xbsj + "】";
                } else {
                  str =
                    str +
                    "【夏" +
                    item.xsbsj +
                    "~" +
                    item.xxbsj +
                    "】 【冬" +
                    item.dsbsj +
                    "~" +
                    item.dxbsj +
                    "】";
                }
                if (index == row.kaoqins.length - 1) {
                  str = str;
                } else {
                  str = str + "，";
                }
              });
            }
            return h("span", str);
          },
        },
        {
          title: "迟到阈值(分钟)",
          key: "cd",
          tooltip: true,
          width: 100,
          align: "center",
        },
        {
          title: "早退阈值(分钟)",
          key: "zt",
          tooltip: true,
          width: 100,
          align: "center",
        },
        {
          title: "允许上班提前(分钟)",
          key: "yxsbtq",
          tooltip: true,
          width: 130,
          align: "center",
        },
        {
          title: "严重迟到阈值(分钟)",
          key: "yzcd",
          tooltip: true,
          width: 130,
          align: "center",
        },
        {
          title: "上班缺卡阈值(分钟)",
          key: "sbqk",
          tooltip: true,
          width: 130,
          align: "center",
        },
        {
          title: "下班缺卡阈值(分钟)",
          key: "xbqc",
          tooltip: true,
          width: 130,
          align: "center",
        },
        {
          title: "旷工阈值(分钟)",
          key: "kg",
          tooltip: true,
          width: 100,
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          tooltip: true,
          width: 90,
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 90,
          align: "center",
        },
      ],
      columnsRule: [
        {
          title: "序号",
          width: 60,
          align: "center",
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "规则名称",
          key: "ruleName",
          tooltip: true,
          width: 160,
          align: "center",
          fixed: 'left',
        },
        {
          title: "启用状态",
          key: "isEnable",
          tooltip: true,
          width: 160,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            if (row.isEnable == 1) {
              return h(
                "span",
                {
                  style: {
                    color: "#25bb96",
                  },
                },
                "已启用"
              );
            }
            return h(
              "span",
              {
                style: {
                  color: "#ff0000",
                },
              },
              "已禁用"
            );
          },
        },
        {
          title: "规则详情",
          tooltip: true,
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            let str = "";
            if (row.ruleItems) {
              let arr = row.ruleItems.map((item) => {
                let itemArr = [];
                if (item.isEnable == 1) {
                  for (const i in item.content) {
                    if (i === "scope") {
                      itemArr.push("范围" + item.content.scope + "米");
                    }
                    if (i === "time") {
                      itemArr.push("时间" + item.content.time + "分钟");
                    }
                    if (i === "yxc") {
                      itemArr.push("允许出" + item.content.yxc + "次");
                    }
                    if (i === "dczc") {
                      itemArr.push("单次最长" + item.content.dczc + "分钟");
                    }
                    if (i === "cwgpd") {
                      itemArr.push("出网格判定" + item.content.cwgpd + "分钟");
                    }
                  }
                }
                return itemArr.join();
              });
              arr = arr.filter((item) => item);
              str = arr.join();
            }
            return h("span", str);
          },
        },
        {
          title: "备注",
          key: "remark",
          tooltip: true,
          width: 160,
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 180,
          align: "center",
        },
      ],
      // data: [],
      planData: [],
      groupData: [],
      ruleData: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 添加计划弹框
      roadCleanPlanVisible: false,
      roadCleanPlanDataPlanId: "",
      // 批量添加计划弹框
      roadCleanPlanBatchVisible: false,
      roadCleanPlanDataBatchPlanId: "",
      // 设置冬夏时间
      workTimeVisible: false,
      workTimeId: null,
      // 作业规则
      tableWorkRuleVisible: false,
      tableWorkRuleData: {},
      // 修改排班
      roadCleanShiftVisible: false,
      roadCleanShiftData: {},
      // 新增 / 修改 作业班组
      workGroupVisible: false,
      workGroupId: "",
      // 作业规则
      workRuleVisible: false,
      workRuleId: "",
      // 添加班组
      roadCleanShiftGroupVisible: false,
      // 添加网格
      gridVisible: false,
      drawerWidth: 0,
      // 计划详情列表
      roadCleanPlanDetailVisible: false,
      roadCleanPlanDetailId: "",
      roadCleanPlanDetailTitle: "",
      roadCleanPlanDetailDate: "",
      // 表格中点击添加班组时的日期
      tableClickDay: {},
      // 批量修改
      roadCleanPlanBatchsVisible: false,
      roadCleanPlanBatchsId: ''
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.planId == items.planId
          })
          this.selectIndexArr.push(data.planId)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.planId == items.planId
            })
            this.selectVisitedIndexArr = [data.planId]
          }
          if (this.currentTabIndex === 1) {
            let data = this.data.find((items, index) => {
              return item.workGroupId == items.workGroupId
            })
            this.selectVisitedIndexArr = [data.workGroupId]
          }
          if (this.currentTabIndex === 2) {
            let data = this.data.find((items, index) => {
              return item.workRuleId == items.workRuleId
            })
            this.selectVisitedIndexArr = [data.workRuleId]
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'personTreeArr': 'getPersonTreeList'
    }),
    workLevelArr () {
      let arr = [{
        levelId: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getRoadAttributeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsPlan
          break;
        case 1:
          columns = this.columnsTeamGroup
          break;
        case 2:
          columns = this.columnsRule
          break;
        default:
          break;
      }
      return columns
    },
    data () {
      let data = []
      switch (this.currentTabIndex) {
        case 0:
          data = this.planData
          break;
        case 1:
          data = this.groupData
          break;
        case 2:
          data = this.ruleData
          break;
        default:
          break;
      }
      return data
    },
    getExportPath () {
      let path = this.$http.exportRoadPlan
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportRoadPlanTem
          break;
        default:
          break;
      }
      return path
    },
    // getReaderFileAction () {
    //   let readerFileAction = '/hss/empPlan/excelReader'
    //   return readerFileAction
    // }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updatePersonTreeList',
      'updateRoadAttributeAllList',
    ]),
    init () {
      this.tableWidths = window.innerWidth - 240
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 240
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.updateDepartmentTreeList()
      this.updatePersonTreeList()
      this.updateRoadAttributeAllList()
      this.getList()
      this.getTimeDetail()
    },
    // 修改姓名
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach((item) => {
        if (item.type === 'user') {
          idArr.push(item.id)
          valueArr.push(item.name);
        }
      })
      this.nameId = idArr.join()
      this.name = valueArr.join()
    },
    // 筛选的选择月
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    // 本月
    onClickCurrentMonth () {
      let time = getCurrentMonth()
      this.date = time[0].substring(0, 7);
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 上月
    onClickNextMonth () {
      let time = getNextMonth()
      this.date = time[0].substring(0, 7);
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.planType = ''
      this.planName = ''
      this.workLevel = ''
      this.date = ''
      this.teamGroupType = ''
      this.teamGroupName = ''
      this.ruleName = ''
      this.nameId = ''
      this.name = ''
      this.buttonIndex = null
      this.departId = ''
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
        case 'edit':
          this.roadCleanPlanBatchsVisible = true
          this.roadCleanPlanBatchsId = this.selectArr.join()
          break;
        default:
          break;
      }
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    // 获取冬夏时间详情
    getTimeDetail () {
      this.$store.dispatch('getTimeCompleteDetail', 2).then(res => {
        let summer = res.summerDate.split('-')
        this.summerTime = summer[0] + '月' + summer[1] + '日'
        let winter = res.winterDate.split('-')
        this.winterTime = winter[0] + '月' + winter[1] + '日'
      })
    },
    // 点击设置冬夏时间
    onClickTime () {
      this.workTimeVisible = true
      this.workTimeId = 2
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.planId)
          break;
        case 1:
          this.selectArr = selection.map(item => item.workGroupId)
          break;
        case 2:
          this.selectArr = selection.map(item => item.workRuleId)
          break;
        default:
          break;
      }
    },
    // 排序
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.planId)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 1) {
        if (this.selectVisitedIndexArr.includes(row.workGroupId)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 2) {
        if (this.selectVisitedIndexArr.includes(row.workRuleId)) {
          return 'table-visited-bgcolor'
        }
      } if (this.selectIndexArr.includes(row.planId)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getPlanList()
          break;
        case 1:
          this.getGroupList()
          break;
        case 2:
          this.getRuleList()
          break;
        default:
          break;
      }
    },
    // 排班计划列表
    getPlanList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        planName: this.planName,
        departmentId: this.departId
      };
      if (!this.date) {
        let time = getCurrentMonth()
        this.date = time[0].substring(0, 7);
      }
      let year = this.date.substring(0, 4);
      let month = this.date.substring(5, 7);
      let currentDate = getYearOrMonthForMonth(year, month)
      params.startDate = currentDate[0]
      params.endDate = currentDate[1]
      this.tableLoading = true;
      this.$http.getRoadPlanListNew(params).then((res) => {
        this.tableLoading = false;
        if (res.code === 200) {
          this.planData = res.result.list;
          this.totalPage = res.result.total;
        }
      })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 获取班组列表
    getGroupList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        kaoqinType: this.teamGroupType == 0 ? '' : this.teamGroupType,
        name: this.teamGroupName,
        depid: "",
        module: 2
      }
      this.tableLoading = true
      this.$http.getWorkGroupList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.groupData = res.result.workGroups
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.ruleName,
        module: 2
      }
      this.tableLoading = true
      this.$http.getWorkRuleList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.ruleData = res.result.workRules
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击table作业规则
    onClickPlanRule (row) {
      this.selectVisitedArr.push(row)
      this.tableWorkRuleData = row
      this.tableWorkRuleVisible = true
    },
    // 点击table计划名称
    onClickPlanDetailList (row) {
      this.selectVisitedArr.push(row)
      this.roadCleanPlanDetailVisible = true
      this.roadCleanPlanDetailTitle = row.planName
      this.roadCleanPlanDetailId = row.planId
      this.roadCleanPlanDetailDate = this.date
    },
    // 初始化table日期列
    dateColumnInit () {
      let year = this.date.substring(0, 4)
      let month = this.date.substring(5, 7)
      let today = new Date().getDate()
      let dayCount = getMonthDays(year, parseInt(month) - 1)
      let dateArr = []
      for (let i = 1; i <= dayCount; i++) {
        let day = i
        if (day < 10) {
          day = "0" + day
        }
        dateArr.push({
          title: month + '/' + day,
          key: year + '-' + month + '-' + day,
          width: 140,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let column = params.column
            let currentDateItem = this.getCurrentDateItem(column.key, row.workPlanDays)
            let isBeyondDate = new Date() >= new Date(column.key)
            let content
            if (currentDateItem) {
              // 有排班
              if (currentDateItem.workGroupId == "0") {
                // 休假
                content = h(
                  "div",
                  {
                    style: {
                      cursor: isBeyondDate ? "" : "pointer",
                    },
                  },
                  [
                    h("Icon", {
                      attrs: {
                        custom: "i-icon icon-xiu",
                        size: "26",
                        color: "#0eb36e",
                      },
                    }),
                  ]
                );
              } else {
                // 班组
                content = h(
                  "div",
                  {
                    style: {
                      lineHeight: "32px",
                      cursor: isBeyondDate ? "" : "pointer",
                    },
                    on: {
                      click: () => {
                        !isBeyondDate &&
                          this.onClickPlanDay(column, row, currentDateItem);
                      },
                    },
                  },
                  [
                    currentDateItem.workGroup &&
                    currentDateItem.workGroup.kaoqins.map((item) => {
                      let type = currentDateItem.workGroup.kaoqinType;
                      let text = "";
                      if (type === 1) {
                        text = `${item.name}(${item.sbsj}~${item.xbsj})`;
                      } else {
                        let date = new Date(year, month, day);
                        let summerDate = new Date(
                          year,
                          this.summerTime.substring(0, 2),
                          this.summerTime.substring(3, 5)
                        );
                        let winterDate = new Date(
                          year,
                          this.winterTime.substring(0, 2),
                          this.winterTime.substring(3, 5)
                        );
                        if (date >= summerDate && date < winterDate) {
                          // 夏季
                          text = `${item.name}(${item.xsbsj}~${item.xxbsj})`;
                        } else {
                          // 冬季
                          text = `${item.name}(${item.dsbsj}~${item.dxbsj})`;
                        }
                      }
                      return h("p", text);
                    }),
                  ]
                );
              }
            } else {
              // 没有排班
              content = h("div", [
                h("p", "该计划下暂无排班"),
                !isBeyondDate &&
                h(
                  "div",
                  {
                    style: {
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    },
                  },
                  [
                    h(
                      "Button",
                      {
                        attrs: {
                          type: "text",
                        },
                        style: {
                          color: "#25bb96",
                          padding: "0 5px",
                          height: "22px",
                          fontSize: "12px",
                          lineHeight: "1",
                        },
                        on: {
                          click: () => {
                            this.onClickPlanAddGroup(column, row);
                          },
                        },
                      },
                      [
                        h("Icon", {
                          attrs: {
                            custom: "i-icon icon-tianjiabiankuang",
                            size: "16",
                          },
                        }),
                        h("span", "添加班组"),
                      ]
                    ),
                    h(
                      "Button",
                      {
                        attrs: {
                          type: "text",
                        },
                        style: {
                          color: "#25bb96",
                          padding: "0 5px",
                          height: "22px",
                          fontSize: "12px",
                          lineHeight: "1",
                        },
                        on: {
                          click: () => {
                            this.onClickPlanHoliday(column, row);
                          },
                        },
                      },
                      [
                        h("Icon", {
                          attrs: {
                            custom: "i-icon icon-shijian",
                            size: "16",
                          },
                        }),
                        h("span", "休假"),
                      ]
                    ),
                  ]
                ),
              ]);
            }
            return h(
              "div",
              {
                style: {
                  height: "64px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                },
              },
              [content]
            );
          },
        });
      }
      this.columnsPlan.splice(6, this.columnsPlan.length - 8, ...dateArr);
    },
    // 获取当前日期item
    getCurrentDateItem (date, arr) {
      let str;
      if (arr && arr.length != 0) {
        str = arr.find((item) => item.date === date);
      }
      return str;
    },
    // 点击table添加班组
    onClickPlanAddGroup (column, row) {
      this.tableClickDay = {
        id: row.workPlanId,
        personId: row.personId,
        date: column.key,
      };
      this.roadCleanShiftGroupVisible = true;
    },
    // 点击table修改班组
    onClickPlanEditGroup () {
      this.roadCleanShiftGroupVisible = true;
    },
    // 添加班组 确认回调
    onClickGroupConfirm (group) {
      let params = {
        personId: this.tableClickDay.personId,
        groupId: group.id,
      };
      if (this.tableClickDay.dayId) {
        params.dayId = this.tableClickDay.dayId;
      } else {
        params.id = this.tableClickDay.id;
        params.date = this.tableClickDay.date;
      }
      this.$store
        .dispatch("createOrEditRoadCleanPlanDay", params)
        .then((res) => {
          this.getList();
          this.tableClickDay = {};
        });
    },
    // 点击table休假
    onClickPlanHoliday (column, row) {
      this.$Modal.confirm({
        title: "休假",
        content: "确认休假?",
        onOk: () => {
          this.tableClickDay = {
            id: row.workPlanId,
            personId: row.personId,
            date: column.key,
          };
          this.onClickGroupConfirm({ id: 0 });
        },
      });
    },
    // 点击table修改休假
    onClickPlanEditHoliday () {
      this.$Modal.confirm({
        title: "休假",
        content: "确认休假?",
        onOk: () => {
          this.onClickGroupConfirm({ id: 0 });
        },
      });
    },
    // 点击table单天排班
    onClickPlanDay (column, row, currentDateItem) {
      this.tableClickDay = {
        id: row.workPlanId,
        dayId: currentDateItem.workPlanDayId,
        personId: row.personId,
        person: row.personName,
        date: column.key,
      };
      this.tableClickDay.text = currentDateItem.workGroup.kaoqins
        .map((item) => {
          let type = currentDateItem.workGroup.kaoqinType;
          let text = "";
          if (type === 1) {
            text = `${item.name}(${item.sbsj}~${item.xbsj})`;
          } else {
            let date = new Date(column.key);
            let summerDate = new Date(
              this.summerTime.substring(0, 2),
              this.summerTime.substring(3, 5)
            );
            let winterDate = new Date(
              this.winterTime.substring(0, 2),
              this.winterTime.substring(3, 5)
            );
            if (date >= summerDate && date < winterDate) {
              // 夏季
              text = `${item.name}(${item.xsbsj}~${item.xxbsj})`;
            } else {
              // 冬季
              text = `${item.name}(${item.dsbsj}~${item.dxbsj})`;
            }
          }
          return text;
        })
        .join();
      this.roadCleanShiftVisible = true;
    },
    // 修改排班计划
    onClickEditPlan (row) {
      this.selectVisitedArr.push(row)
      this.roadCleanPlanVisible = true;
      this.roadCleanPlanDataPlanId = row.planId;
    },
    // 批量清空
    onDeleteClear (arr) {
      this.$Modal.confirm({
        title: "清空",
        content: "确认清空?",
        loading: true,
        onOk: () => {
          let params = {
            ids: arr,
          };
          this.$http.deleteRoadPlanNew(params).then((res) => {
            this.$Message.info(res.message);
            if (res.code === 200) {
              this.getList()
              this.$Modal.remove()
              this.onChangClearRowStyle()
            }
          }).catch((err) => {
            this.$Modal.remove()
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 清空排班计划
    onClickClearPlan (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteClear([row.planId])
    },
    // 修改班组
    onClickEditGroup (row) {
      this.selectVisitedArr.push(row)
      this.workGroupVisible = true
      this.workGroupId = row.workGroupId
    },
    // 删除班组
    onClickDeleteGroup (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: "删除",
        content: "确认删除?",
        onOk: () => {
          let params = {
            workGroupIdList: [row.workGroupId],
            module: 3
          }
          this.$http.deleteWorkGroupDlbj(params).then(res => {
            if (res.code === 200) {
              if (res.result.workPlanIdList.length) {
                this.$Message.info({
                  content: '此班组已进行排班，删除会导致数据错误',
                  duration: 3
                })
              } else {
                this.getList()
                this.$Message.info({
                  content: res.message,
                  duration: 3
                })
              }
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      });
    },
    // 修改作业规则
    onClickEditRule (row) {
      this.selectVisitedArr.push(row)
      this.workRuleVisible = true;
      this.workRuleId = row.workRuleId;
    },
    // 修改作业规则状态
    onClickStatusRule (row) {
      this.selectVisitedArr.push(row)
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: "禁用",
          content: "确认禁用?",
          onOk: () => {
            let params = {
              id: row.workRuleId,
              status: 0,
            };
            this.$store
              .dispatch("createOrEditWorkRule", params)
              .then((res) => {
                this.getList();
                // this.$store.dispatch('updateCarTypeAllList', true)
              })
              .catch((err) => {
                this.$Message.info(err.message);
              });
              this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        });
      } else {
        this.$Modal.confirm({
          title: "启用",
          content: "确认启用?",
          onOk: () => {
            let params = {
              id: row.workRuleId,
              status: 1,
            };
            this.$store
              .dispatch("createOrEditWorkRule", params)
              .then((res) => {
                this.getList();
                // this.$store.dispatch('updateCarTypeAllList', true)
              })
              .catch((err) => {
                this.$Message.info(err.message);
              });
              this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        });
      }
    },
    // 删除作业规则
    onClickDeleteRule (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: "删除",
        content: "确认删除?",
        onOk: () => {
          let params = {
            workRuleIdList: [row.workRuleId]
          }
          this.$http.deleteWorkRuleDlbj(params).then(res => {
            if (res.code === 200) {
              if (res.result.workPlanIdList.length) {
                this.$Message.info({
                  content: '此规则已进行排班，删除会导致数据错误',
                  duration: 3
                })
              } else {
                this.getList()
                this.$Message.info({
                  content: res.message,
                  duration: 3
                })
              }
              this.onChangClearRowStyle()
            }
          })
        },
        onCancel: () => {
            this.onChangClearRowStyle()
          },
      });
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 导出文件数据
    getExportData (value) {
      let data = {};
      switch (this.currentTabIndex) {
        case 0:
          data = {
            // endTime: "",
            planName: this.planName,
            // startTime: "",
          };
          // if (!this.date) {
          //   let time = getCurrentMonth();
          //   this.date = time[0].substring(0, 7);
          // }
          // data.date = this.date;
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.planId
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            data.ids = []
          }
          let year = this.date.substring(0, 4);
          let month = this.date.substring(5, 7);
          let currentDate = getYearOrMonthForMonth(year, month)
          data.startTime = currentDate[0]
          data.endTime = currentDate[1]
          if (value == 'all') {
            data.startTime = ''
            data.endTime = ''
          }
          break;
        default:
          break;
      }
      return data;
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info("导入成功");
        this.getList();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>