<template>
	<div class="">
		<div class="tab" :class="{'tab-bgc':index%2==0,'tab-bottom':index==length-1}" :style="{'width':tabWidth+'px'}">
			<!-- <div class="tab-item tab-check">
				
			</div> -->
			<div class="tab-item tab-check">
				{{index+1<10?'0'+(index+1):index+1}}
			</div>
			<div class="tab-item tab-name">
				{{source.employeeName}}
			</div>
			<div class="tab-item tab-bumen">
				{{source.departmentName}}
			</div>
			<div class="tab-item" :class="{'bordernone':key===0,'box-last':key==list.length-1}" v-for="(item,key) in list" :key="key">
				<div v-if="item.type==1&&key!=list.length-1" :class="{'cursor-pointer':screen(key)}" @click.stop="onClickTabAdd(key,true)">
					<template class="" v-if="item.obj.kaoQinType==2">
						<div v-if="mon>9||mon<5">
							<div class="">
								{{item.obj.workKaoQinList[0].name}}({{item.obj.workKaoQinList[0].dsbsj}}~{{item.obj.workKaoQinList[0].dxbsj}})
							</div>
							<div class="" v-if="item.obj.workKaoQinList.length>1">
								{{item.obj.workKaoQinList[1].name}}({{item.obj.workKaoQinList[1].dsbsj}}~{{item.obj.workKaoQinList[1].dxbsj}})
							</div>
						</div>
						<div v-else-if="mon>4&&mon<10">
							<div class="">
								{{item.obj.workKaoQinList[0].name}}({{item.obj.workKaoQinList[0].xsbsj}}~{{item.obj.workKaoQinList[0].xxbsj}})
							</div>
							<div class="" v-if="item.obj.workKaoQinList.length>1">
								{{item.obj.workKaoQinList[1].name}}({{item.obj.workKaoQinList[1].xsbsj}}~{{item.obj.workKaoQinList[1].xxbsj}})
							</div>
						</div>
					</template>
					<template class="" v-else>
						<div class="">
							{{item.obj.workKaoQinList[0].name}}({{item.obj.workKaoQinList[0].sbsj}}~{{item.obj.workKaoQinList[0].xbsj}})
						</div>
						<div class="" v-if="item.obj.workKaoQinList.length>1">
							{{item.obj.workKaoQinList[1].name}}({{item.obj.workKaoQinList[1].sbsj}}~{{item.obj.workKaoQinList[1].xbsj}})
						</div>
					</template>
				</div>
				<div class="tab-add cursor-pointer" v-else-if="screenAdd(item,key)" @click.stop="onClickTabAdd(key,false)">
					该计划下暂无排班
					<div class="tab-add-txt">
						<div class="tab-add-txt-icon">
							+
						</div>
						添加数组
					</div>
				</div>
				<div v-else-if="key!=list.length-1">
					该计划下暂无排班
				</div>
			</div>
			<!-- <div class=" tab-postition">
				<div class="tab-item tab-check">
					
				</div>
				<div class="tab-item tab-check">
					{{index+1}}
				</div>
				<div class="tab-item tab-name">
					{{source.employeeName}}
				</div>
				<div class="tab-item tab-bumen">
					{{source.departmentName}}
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import bus from '@/utils/bus'
	export default {
	    props: {
	      index: { // 每一行的索引
	        type: Number
	      },
	      source: { // 每一行的内容
	        type: Object,
	        default () {
	          return {}
	        }
	      },
		  timeObj: { // 每一行的内容
	        type: Object,
	        default () {
	          return {}
	        }
	      },
		  tabWidth:{
			type: Number
		  },
		  month:{
			type: Number
		  },
		  mon:{
			type: Number
		  },
		  yearTxt:{
			type: Number
		  },
		  day:{
			type: Number
		  },
		  size:{
			type: Number
		  },
		  length:{
			type: Number
		  }
	    },
		data(){
			return{
				list:[]
			}
		},
		watch:{
			size(newVal,oldVal){
				this.init()
			}
		},
		mounted(){
			this.init()
		},
		methods:{
			init(){
				let arr = [],list = [];
				this.list = [];
				this.source.empPlanDetailVoList.forEach(item=>{
					arr.push(Number(item.date.slice(-2)))
					item.day = Number(item.date.slice(-2))
					list.push(item)
				})
				for (let i = 1; i <= this.month; i++) {
					if(arr.indexOf(i)!=-1){
						this.list.push({
							type:1,
							id:i,
							obj:list[arr.indexOf(i)]
						})
					}else{
						this.list.push({
							type:2
						})
					}
				}
			},
			onClickTabAdd(days,type){
				if(this.yearTxt<this.timeObj.year||this.yearTxt==this.timeObj.year&&this.mon<this.timeObj.month||this.yearTxt==this.timeObj.year&&this.mon==this.timeObj.month&&this.day>=days+1){
					return false
				}
				let time = this.yearTxt+'-'+this.mon+'-'+(days+1)
				console.log(time)
				let list = null;
				this.source.empPlanDetailVoList.forEach(item=>{
					if(item.day==days+1){
						list = item
					}
				})
				this.source.workPlanId = undefined
				bus.$emit('tableClean',{
					type:type,
					index:this.index,
					time:time,
					key:days+1,
					source:this.source,
					list:list
				})
			},
			screen(index,item){
				if(this.yearTxt==this.timeObj.year&&this.mon==this.timeObj.month&&this.day<index+1&&index!=this.list.length-1){
					return true
				}else if(this.yearTxt==this.timeObj.year&&this.mon>this.timeObj.month){
					return true
				}else if(this.yearTxt>this.timeObj.year){
					return true
				}else{
					return false
				}
			},
			screenAdd(item,index){
				if(item.type==2&&this.yearTxt==this.timeObj.year&&this.mon==this.timeObj.month&&this.day<index+1&&index!=this.list.length-1||item.type==2&&this.yearTxt==this.timeObj.year&&this.mon>this.timeObj.month||item.type==2&&this.yearTxt>this.timeObj.year){
					return true
				}else{
					return false
				}
			}
		}
	  }
</script>

<style scoped lang="scss">
	.tab{
		display: flex;
		align-items: center;
		position: relative;
		.tab-item {
			width: 150px;
			height: 80px;
			border-top: 1px solid #e8eaec;
			border-left: 1px solid #e8eaec;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.tab-check{
			width: 50px;
		}
		.tab-name{
			width: 100px;
		}
		.tab-bumen{
			width: 120px;
		}
	}
	// .tab-postition{
	// 	width: 320px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	display: flex;
	// 	align-items: center;
	// }
	.tab-first{
		background-color: skyblue;
		position: absolute;
		left: 0;
		top: 0;
	}
	.tab-add{
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		.tab-add-txt{
			color:#51c9ab;
			display: flex;
			align-items: center;
			.tab-add-txt-icon{
				width: 15px;
				height: 15px;
				border-radius: 50%;
				border: 1px solid #51c9ab;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.tab-bgc{
		background-color: #f8f8f9;
	}
	.box-last{
		width: 100px !important;
	}
	.tab-bottom{
		border-bottom: 1px solid #e8eaec;
	}
	.bordernone{
		border-left: none !important;
	}
</style>
