<template>
  <Drawer :title="title"
          :mask="false"
          closable
          :width="width"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="topbar-search m-b-10">
      <div class="flex a-center j-between">
        <div class="flex">
          <MyTreeSelect :value="name"
                        placeholder="姓名"
                        clearable
                        class="m-r-10"
                        style="width: 160px"
                        :data="personTreeArr"
                        search
                        checked
                        @onCheckChange="onChangeName"></MyTreeSelect>
        </div>
        <div class="flex">
          <Button type="primary"
                  class="m-l-10"
                  @click.stop="onClickSearch">
            <Icon custom="i-icon icon-sousuo"
                  size="16"></Icon>
            查询
          </Button>
          <Button class="m-l-10"
                  @click.stop="onClickReset">
            <Icon custom="i-icon icon-shuaxin"
                  size="16"></Icon>
            重置
          </Button>
        </div>
      </div>
    </div>
    <!-- <Table ref="table"
           :height="tableHeight"
           :columns="columnsPlan"
           :data="data"
           border
           stripe
           :loading="tableLoading"
           @on-selection-change="onChangeSelect">
      <template slot-scope="{ row }"
                slot="operation">
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="onClickEditPlan(row)">修改</Button>
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="onClickClearPlan(row)">清空</Button>
      </template>
    </Table> -->
    <div style="overflow: hidden;height: 50px;position: relative;"
         ref="externalForm">
      <div class="tab tab-first"
           :style="{'width':tabWidth+'px','left':scrollLeft+'px'}">
        <!-- <div class="tab-item tab-check"></div> -->
        <div class="tab-item tab-check">
          序号
        </div>
        <div class="tab-item tab-name">
          姓名
        </div>
        <div class="tab-item tab-bumen">
          所属部门
        </div>
        <div class="tab-item"
             :class="{'tab-last':key==month-1,'bordernone':key===0}"
             v-for="(item,key) in month"
             :key="key">
          <div class=""
               v-if="key!=month.length-1">
            {{mon<10?'0'+mon:mon}}/{{key+1<10?'0'+(key+1):key+1}}
          </div>
        </div>
      </div>
    </div>
    <VirtualList class="virList"
                 :style="{'height':tableHeight+'px'}"
                 :data-key="'departmentId'"
                 :data-sources="data"
                 :data-component="itemComponent"
                 :keeps="15"
                 :estimate-size="80"
                 :extra-props="{'tabWidth':tabWidth,'month':month,'mon':mon,'day':day,'size':size,'length':data.length,'timeObj':timeObj,'yearTxt':yearTxt}"
                 @scroll="scroll"
                 ref="systemForm" />
    <div class="tab tab-leftItem tab-first"
         style="top: 58px;left: 16px;">
      <!-- <div class="tab-item tab-check"></div> -->
      <div class="tab-item tab-check">
        序号
      </div>
      <div class="tab-item tab-name">
        姓名
      </div>
      <div class="tab-item tab-bumen"
           style="border-right: 1px solid #e8eaec;">
        所属部门
      </div>
    </div>
    <div class="tab-leftFather"
         :style="{'height':(tableHeight-10)+'px'}">
      <div class="tab-left"
           :style="{'top':scrollTop+'px'}">
        <div class="tab tab-leftItem"
             v-for="(item,index) in data"
             :class="{'tab-bgc':index%2==0,'tab-bottom':index==data.length-1}">
          <!-- <div class="tab-item tab-check tab-left-item">

					</div> -->
          <div class="tab-item tab-check tab-left-item">
            {{index+1<10?'0'+(index+1):index+1}}
          </div>
          <div class="tab-item tab-name tab-left-item">
            {{item.employeeName}}
          </div>
          <div class="tab-item tab-bumen tab-left-item item-right">
            {{item.departmentName}}
          </div>
        </div>
      </div>
    </div>
    <div class="tab-right-postition tab-right-item">
      操作
    </div>
    <div class="tab-right"
         :style="{'height':(tableHeight-10)+'px'}">
      <div class="tab-right-model"
           :style="{'top':scrollTop+'px'}">
        <div class="tab-right-item"
             v-for="(item,key) in data.length"
             :class="{'tab-bottom':key==data.length-1}"
             @click.stop="onClickClearPlan(data[key])">
          清除
        </div>
      </div>
    </div>
    <Page class="m-t-10 m-l-10"
          :total="totalPage"
          :current="pageNum"
          :page-size="pageSize"
          show-total
          show-sizer
          show-elevator
          @on-change="onPageChange"
          @on-page-size-change="onPageSizeChange" />

    <!-- 添加班组 -->
    <RoadCleanShiftGroupModal v-model="roadCleanShiftGroupVisible"
                              @onClickConfirm="onClickGroupConfirm">
    </RoadCleanShiftGroupModal>

    <!-- 修改排班 -->
    <RoadCleanShiftModal v-model="roadCleanShiftVisible"
                         :data="tableClickDay"
                         @onClickEditGroup="onClickPlanEditGroup"
                         @onClickHoliday="onClickPlanEditHoliday"></RoadCleanShiftModal>
  </Drawer>
</template>

<script>
import {
  getCurrentMonth,
  getNextMonth,
  getMonthDays,
} from "@/utils/dateRangUtil";
import RoadCleanShiftGroupModal from "@/components/product/admin/modal/work/RoadCleanShiftGroupModal";
import RoadCleanShiftModal from "@/components/product/admin/modal/work/RoadCleanShiftModal";
import {
  mapActions,
  mapGetters
} from "vuex";
import MyTreeSelect from "@/components/common/MyTreeSelect";
import VirtualList from 'vue-virtual-scroll-list'
import Item from './ScrollListItem.vue'
import bus from '@/utils/bus'
export default {
  components: {
    RoadCleanShiftGroupModal,
    RoadCleanShiftModal,
    MyTreeSelect,
    VirtualList
  },
  model: {
    prop: "visible",
    event: "onChange",
  },
  props: {
    visible: Boolean,
    title: String,
    width: Number,
    dataId: String,
    date: String,
  },
  data () {
    return {
      itemComponent: Item,
      left: 0,
      month: '',
      mon: '',
      day: '',
      size: 0,//data发生操作
      top: 0,//左右栏与排班同步
      yearTxt: '',//年
      timeObj: {//当前年月
        year: '',
        month: ''
      },
      name: "",
      nameId: "",
      height: 0,
      summerTime: "",
      winterTime: "",
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsPlan: [{
        type: "selection",
        width: 45,
        fixed: "left",
        align: "center",
      },
      {
        title: "序号",
        width: 45,
        fixed: "left",
        align: "center",
        render: (h, params) => {
          let index = params.index + 1;
          let num = (this.pageNum - 1) * this.pageSize + index;
          return h("span", num);
        },
      },
      {
        title: "姓名",
        key: "employeeName",
        width: 70,
        tooltip: true,
        fixed: "left",
        align: "center",
      },
      {
        title: "所属部门",
        key: "departmentName",
        width: 110,
        tooltip: true,
        fixed: "left",
        align: "center",
      },
      {
        title: "操作",
        slot: "operation",
        width: 90,
        fixed: "right",
        align: "center",
      },
      ],
      data: [],
      // 添加班组
      roadCleanShiftGroupVisible: false,
      // 修改排班
      roadCleanShiftVisible: false,
      roadCleanShiftData: {},
      // 表格中点击添加班组时的日期
      tableClickDay: {},
    };
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.init();
      } else {
        this.$emit("onClickCancel");
      }
    },
    dataId (newValue) {
      newValue && this.getDetail();
    },
  },
  computed: {
    ...mapGetters({
      personTreeArr: "getPersonTreeList",
    }),
    scrollLeft () {
      return 0 - this.left
    },
    scrollTop () {
      // if (this.top >= 680 && this.top < 860) {
      // 	// return 0-this.top-7
      // 	return 0 - this.top
      // } else if (this.top >= 860) {
      // 	return -870
      // } else {
      return 0 - this.top
      // }
    },
    tabWidth () {
      // return this.month * 150 + 320 - 50
      return this.month * 150 + 270 - 50
    }
  },
  created () {
    this.tableHeight = window.innerHeight - 270;
    // this.month = this.mGetDate()
  },
  mounted () {
    // this.height = window.innerHeight - 160;
    bus.$on('tableClean', obj => {
      obj.type
        ? this.onClickPlanDay({ key: obj.time }, obj.source, obj.list)
        : this.onClickPlanAddGroup({ key: obj.time }, obj.source)
    })
  },
  methods: {
    mGetDate () {
      var date = new Date();
      this.day = date.getDate();//当天天数
      let newYear = date.getFullYear()
      let newMonth = date.getMonth() + 1
      this.timeObj = {//当天年月
        year: newYear,
        month: newMonth
      }
      this.yearTxt = this.date.slice(0, 4) * 1;//表格年数
      this.mon = this.date.slice(-2) * 1;//表格月数
      var d = new Date(this.yearTxt, this.mon, 0);//表格天数
      return d.getDate();
    },
    scroll (e) {
      this.$nextTick(() => {
        this.left = e.srcElement.scrollLeft
        this.top = e.srcElement.scrollTop
        this.$refs.externalForm.scrollTop = e.srcElement.scrollLeft
      })
    },
    ...mapActions(["updatePersonTreeList"]),
    init () {
      this.getTimeDetail();
      this.updatePersonTreeList();
      this.tableHeight = window.innerHeight - 270;
    },
    getDetail () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        planId: this.dataId,
        employeeList: this.nameId ? this.nameId.split(',') : []
      };
      this.dateColumnInit();
      this.tableLoading = true;
      this.$http.getRoadPlanDetailListNew(params).then((res) => {
        this.tableLoading = false;
        if (res.code === 200) {
          this.month = this.mGetDate() + 1
          this.totalPage = res.result.total;
          this.data = res.result.list;
          this.size++
        }
      }).catch((err) => {
        this.tableLoading = false;
      });
    },
    // 修改姓名
    onChangeName (section) {
      let idArr = [];
      let valueArr = [];
      section.forEach((item) => {
        if (item.type === "user") {
          idArr.push(item.id);
          valueArr.push(item.name);
        }
      });
      this.nameId = idArr.join();
      this.name = valueArr.join();
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map((item) => item.id);
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event;
      this.getDetail();
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event;
      this.getDetail();
    },
    // 获取冬夏时间详情
    getTimeDetail () {
      this.$store.dispatch("getTimeCompleteDetail", 2).then((res) => {
        let summer = res.summerDate.split("-");
        this.summerTime = summer[0] + "月" + summer[1] + "日";
        let winter = res.winterDate.split("-");
        this.winterTime = winter[0] + "月" + winter[1] + "日";
      });
    },
    // 初始化table日期列
    dateColumnInit () {
      let year = this.date.substring(0, 4);
      let month = this.date.substring(5, 7);
      let today = new Date().getDate();
      let dayCount = getMonthDays(year, parseInt(month) - 1);
      let dateArr = [];
      for (let i = 1; i <= dayCount; i++) {
        let day = i;
        if (day < 10) {
          day = "0" + day;
        }
        dateArr.push({
          title: month + "/" + day,
          key: year + "-" + month + "-" + day,
          width: 140,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            let column = params.column;
            let currentDateItem = this.getCurrentDateItem(column.key, row.empPlanDetailVoList);
            let isBeyondDate = new Date() >= new Date(column.key);
            let content;
            if (currentDateItem) {
              // 有排班
              if (currentDateItem.workGroupId == "0") {
                // 休假
                content = h(
                  "div", {
                  style: {
                    cursor: isBeyondDate ? "" : "pointer",
                  },
                },
                  [
                    h("Icon", {
                      attrs: {
                        custom: "i-icon icon-xiu",
                        size: "26",
                        color: "#0eb36e",
                      },
                    }),
                  ]
                );
              } else {
                // 班组
                content = h(
                  "div", {
                  style: {
                    lineHeight: "32px",
                    cursor: isBeyondDate ? "" : "pointer",
                  },
                  on: {
                    click: () => {
                      !isBeyondDate && this.onClickPlanDay(column, row,
                        currentDateItem)
                    },
                  },
                },
                  [
                    currentDateItem.workKaoQinList.map((item) => {
                      let type = currentDateItem.kaoQinType;
                      let text = "";
                      if (type === 1) {
                        text = `${item.name}(${item.sbsj}~${item.xbsj})`;
                      } else {
                        let date = new Date(year, month, day);
                        let summerDate = new Date(
                          year,
                          this.summerTime.substring(0, 2),
                          this.summerTime.substring(3, 5)
                        );
                        let winterDate = new Date(
                          year,
                          this.winterTime.substring(0, 2),
                          this.winterTime.substring(3, 5)
                        );
                        if (date >= summerDate && date < winterDate) {
                          // 夏季
                          text =
                            `${item.name}(${item.xsbsj}~${item.xxbsj})`;
                        } else {
                          // 冬季
                          text =
                            `${item.name}(${item.dsbsj}~${item.dxbsj})`;
                        }
                      }
                      return h("p", text);
                    }),
                  ]
                );
              }
            } else {
              // 没有排班
              content = h("div", [
                h("p", "该计划下暂无排班"),
                !isBeyondDate &&
                h(
                  "div", {
                  style: {
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  },
                },
                  [
                    h(
                      "Button", {
                      attrs: {
                        type: "text",
                      },
                      style: {
                        color: "#25bb96",
                        padding: "0 5px",
                        height: "22px",
                        fontSize: "12px",
                        lineHeight: "1",
                      },
                      on: {
                        click: () => {
                          this.onClickPlanAddGroup(column, row);
                        },
                      },
                    },
                      [
                        h("Icon", {
                          attrs: {
                            custom: "i-icon icon-tianjiabiankuang",
                            size: "16",
                          },
                        }),
                        h("span", "添加班组"),
                      ]
                    ),
                    h(
                      "Button", {
                      attrs: {
                        type: "text",
                      },
                      style: {
                        color: "#25bb96",
                        padding: "0 5px",
                        height: "22px",
                        fontSize: "12px",
                        lineHeight: "1",
                      },
                      on: {
                        click: () => {
                          this.onClickPlanHoliday(column, row);
                        },
                      },
                    }
                      //  [
                      //   h('Icon', {
                      //     attrs: {
                      //       custom: 'i-icon icon-shijian',
                      //       size: '16'
                      //     }
                      //   }),
                      //   h('span', '休假')
                      // ]
                    ),
                  ]
                ),
              ]);
            }
            return h(
              "div", {
              style: {
                height: "64px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
              },
            },
              [content]
            );
          },
        });
      }
      this.columnsPlan.splice(3, this.columnsPlan.length - 5, ...dateArr);
    },
    // 获取当前日期item
    getCurrentDateItem (date, arr) {
      let str;
      if (arr && arr.length != 0) {
        str = arr.find((item) => item.date === date);
      }
      return str;
    },
    // 点击table添加班组
    onClickPlanAddGroup (column, row) {
      this.tableClickDay = {
        id: row.workPlanId,
        personId: row.employeeId,
        date: column.key,
        gridId: row.gridId,
      };
      this.roadCleanShiftGroupVisible = true;
    },
    // 点击table修改班组
    onClickPlanEditGroup () {
      this.roadCleanShiftGroupVisible = true;
    },
    // 添加班组 确认回调
    onClickGroupConfirm (group) {
      let params = {}

      // 新增
      if (this.tableClickDay.empPlanWorkGroupId) {
        params.workGroupId = group.id
        params.empPlanWorkGroupId = this.tableClickDay.empPlanWorkGroupId
        params.empPlanDateId = this.tableClickDay.empPlanDateId
        params.empPlanEmployeeId = this.tableClickDay.empPlanEmployeeId
      } else {
        params = {
          planId: this.dataId,
          employeeId: this.tableClickDay.personId,
          date: this.tableClickDay.date,
          workGroupId: group.id
        }
      }
      this.$http.createRoadPlanDayNew(params).then((result) => {
        this.getDetail()
        this.tableClickDay = {}
      })
      // }
    },
    // 点击table休假
    onClickPlanHoliday (column, row) {
      // this.$Modal.confirm({
      //   title: '休假',
      //   content: '确认休假?',
      //   onOk: () => {
      //     this.tableClickDay = {
      //       id: row.workPlanId,
      //       personId: row.personId,
      //       date: column.key
      //     }
      //     this.onClickGroupConfirm({ id: 0 })
      //   }
      // })
    },
    // 点击table修改休假
    onClickPlanEditHoliday () {
      // this.$Modal.confirm({
      //   title: '休假',
      //   content: '确认休假?',
      //   onOk: () => {
      //     this.onClickGroupConfirm({ id: 0 })
      //   }
      // })
    },
    // 点击table单天排班
    onClickPlanDay (column, row, currentDateItem) {
      this.tableClickDay = {
        id: row.workPlanId,
        dayId: currentDateItem.empPlanDateId,
        personId: row.employeeId,
        person: row.employeeName,
        date: column.key,
        gridId: row.gridId,
        empPlanWorkGroupId: currentDateItem.empPlanWorkGroupId,
        empPlanDateId: currentDateItem.empPlanDateId,
        empPlanEmployeeId: currentDateItem.empPlanEmployeeId
      };
      this.tableClickDay.text = currentDateItem.workKaoQinList.map((item) => {
        let type = currentDateItem.kaoQinType;
        let text = "";
        if (type === 1) {
          text = `${item.name}(${item.sbsj}~${item.xbsj})`;
        } else {
          let date = new Date(column.key);
          let summerDate = new Date(
            this.summerTime.substring(0, 2),
            this.summerTime.substring(3, 5)
          );
          let winterDate = new Date(
            this.winterTime.substring(0, 2),
            this.winterTime.substring(3, 5)
          );
          if (date >= summerDate && date < winterDate) {
            // 夏季
            text = `${item.name}(${item.xsbsj}~${item.xxbsj})`;
          } else {
            // 冬季
            text = `${item.name}(${item.dsbsj}~${item.dxbsj})`;
          }
        }
        return text;
      }).join();
      this.roadCleanShiftVisible = true;
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData();
        this.$emit("update:dataId", "");
        this.$emit("update:date", "");
        this.$emit("onChange", false);
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1;
      this.getDetail();
    },
    // 点击重置
    onClickReset () {
      this.resetData();
      this.onClickSearch();
    },
    resetData () {
      this.nameId = "";
      this.name = "";
    },
    // 清空排班计划
    onClickClearPlan (row) {
      let empPlanEmployeeIdList = [row.empPlanEmployeeId];
      // let empPlanDateIdList = [];
      // let empPlanWorkGroupIdList = [];
      // row.empPlanDetailVoList.forEach((item) => {
      // 	if(item.empPlanDateId){
      // 		empPlanDateIdList.push(item.empPlanDateId);
      // 	}
      // 	if(item.empPlanEmployeeId&&empPlanEmployeeIdList.length==0){
      // 		empPlanEmployeeIdList.push(item.empPlanEmployeeId);
      // 	}
      // 	if(item.empPlanWorkGroupId&&empPlanWorkGroupIdList.length==0){
      // 		empPlanWorkGroupIdList.push(item.empPlanWorkGroupId);
      // 	}
      // });
      this.$Modal.confirm({
        title: "清空",
        content: "确认清空?",
        loading: true,
        onOk: () => {
          let params = {
            empPlanEmployeeIdList: empPlanEmployeeIdList
          };
          this.$http
            .deleteRoadPlanDayNew(params)
            .then((res) => {
              this.$Message.info(res.message);
              if (res.code === 200) {
                this.getDetail();
                this.$Modal.remove();
              }
            })
            .catch((err) => {
              this.$Modal.remove();
            });
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep {
  .ivu-drawer {
    top: 60px;
    bottom: 0;
  }
}

.virList {
  /* height: 741px; */
  overflow: auto;
  position: relative;
}

.tab {
  display: flex;
  align-items: center;

  .tab-item {
    width: 150px;
    height: 50px;
    border-top: 1px solid #e8eaec;
    border-left: 1px solid #e8eaec;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-check {
    width: 50px;
  }

  .tab-name {
    width: 100px;
  }

  .tab-bumen {
    width: 120px;
  }
}

.tab-first {
  overflow: auto;
  position: absolute;
  left: 0;
  top: 0;
}

.tab-left {
  /* width: 320px; */
  width: 270px;
  position: absolute;
  left: 0;
}

.tab-leftItem {
  background-color: #ffffff;

  .tab-left-item {
    height: 80px;
  }
}

.tab-leftFather {
  /* width: 320px; */
  width: 270px;
  /* height: 731px; */
  position: absolute;
  top: 108px;
  left: 16px;
  overflow: hidden;
}

.item-right {
  border-right: 1px solid #e8eaec;
  text-align: center;
}

.tab-bgc {
  background-color: #f8f8f9;
}

.bordernone {
  border-left: none !important;
}

.tab-right-postition {
  background-color: #ffffff;
  position: absolute;
  top: 58px;
  right: 22px;
}

.tab-right-item {
  width: 100px;
  height: 50px;
  border-top: 1px solid #e8eaec;
  border-left: 1px solid #e8eaec;
  border-right: 1px solid #e8eaec;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-right {
  width: 100px;
  /* height: 731px; */
  position: absolute;
  top: 108px;
  right: 22px;
  overflow: hidden;

  .tab-right-model {
    position: absolute;
    right: 0;

    .tab-right-item {
      height: 80px;
      color: #25bb96;
    }
  }
}

.tab-bottom {
  border-bottom: 1px solid #e8eaec;
}

.tab-last {
  width: 100px !important;
}
</style>
