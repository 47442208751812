<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="title"
         width="600"
         class-name="vertical-center-modal">
    <div class="shift-tip">
      <Icon custom="i-icon icon-tishi1"
            size="16"
            color="#ff9b77"
            class="m-r-5" />
      <span>双向顶岗打开，交换顶岗人与被顶岗人当日班次;关闭时清空顶岗人原有班次改为被顶岗人班次<p class="li-title">一经顶岗，不允许恢复</p></span>
    </div>
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>考勤段名称：</p>
          <MyTreeSelect :value="name"
                   placeholder="考勤段名称"
                   clearable
                        :data="nameTreeArr"
                        @onSelectChange="onChangeName">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>双向顶岗：</p>
          <i-switch v-model="bothway" />
        </li>
      </ul>
      <div class="allpeople">
        <div class="allpeople-left">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="allpeople-right">
          <div class="title">
            <p class="li-title">工种：</p>
            <Select v-model="profession">
              <Option v-for="item in professionList"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>
          <div class="cont">
            <div class="cont-box">
              <div class="cont-item"
                   v-for="(item,index) in peopleList"
                   :key="index"
                   @click.stop="onClickPeople(index)"
                   :class="{current : peopleIndex == index,green:item.status=='leisure',orange:item.status=='work',red:item.status=='leave'}">
                <p class="li-title">{{item.surname}}</p>
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
          <ul class="footer">
            <li>
              <span class="green"></span>
              空闲
            </li>
            <li>
              <span class="orange"></span>
              有排班
            </li>
            <li>
              <span class="red"></span>
              请假
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import MyTree from '@/components/common/MyTree';
export default {
  components: {
    MyTreeSelect,
    MyTree
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '修改排班'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      nameId: '',
      bothway: false,
      profession: 0,
      deptid: '',
      nameTreeArr: [],
      people: '',
      peopleIndex: null,
      professionList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '驾驶员'
        },
        {
          id: 2,
          name: '清洁工'
        }
      ],
      peopleList: [
        {
          surname: '隋',
          name: '隋杰',
          status: 'leisure'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'work'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'leave'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'leisure'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'leisure'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'work'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'leave'
        },
        {
          surname: '隋',
          name: '隋杰',
          status: 'work'
        },
      ],
      departmentTreeArr: []
    };
  },
  computed: {
    ...mapGetters({

    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 选择考勤段名称
    onChangeName (section) {
      this.name = section.name
      this.nameId = section.id
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.deptid = data.id
    },
    // 选择人员
    onClickPeople (index) {
      this.peopleIndex = index
      // this.people = this.peopleList[index].id
    },
    onClickConfirm () {
      // if (!this.time) return this.$Message.info('请输入请假时间')

    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {

    }
  },
}
</script>

<style lang='scss' scoped>
.shift-tip {
  padding: 5px 18px;
  margin-bottom: 16px;
  font-size: 12px;
  color: #6c6d6e;
  background-color: #fafbfc;
  display: flex;
  span {
    p {
      color: #1785fe;
    }
  }
}
.allpeople {
  height: 300px;
  display: flex;
  .allpeople-left {
    width: 200px;
    // min-width: 200px;
    height: 100%;
    border: 1px solid #ddd;
    margin-right: 10px;
    overflow: auto;
  }
  .allpeople-right {
    flex: 1;
    height: 100%;
    border: 1px solid #ddd;
    position: relative;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      padding: 5px;
      background: #ffffff;
      p {
        line-height: 32px;
        min-width: 50px;
        text-align: right;
      }
    }
    .cont {
      overflow: auto;
      flex: 1;
      .cont-box {
        // height: 800px;
        display: flex;
        flex-wrap: wrap;
        // padding: 8px;
        .cont-item {
          width: 57px;
          height: 50px;
          text-align: center;
          color: #ffffff;
          margin-right: 8px;
          margin-bottom: 8px;
          border-radius: 4px;
          cursor: pointer;
          p {
            padding-top: 3px;
            font-size: 16px;
          }
          span {
            font-size: 12px;
          }
        }
        .cont-item:nth-child(5n + 1) {
          margin-left: 8px;
        }
      }
    }
    .footer {
      line-height: 35px;
      display: flex;
      border-top: 1px solid #ddd;
      background: #ffffff;
      li {
        margin-left: 8px;
        // display: flex;
        // align-items: center;
        span {
          display: inline-block;
          width: 14px;
          height: 12px;
          margin-right: 2px;
          vertical-align: -1px;
        }
      }
      // li:nth-child(1) {
      //   span {
      //     background: #0eb36e;
      //   }
      // }
      // li:nth-child(2) {
      //   span {
      //     background: #fe9400;
      //   }
      // }
      // li:nth-child(3) {
      //   span {
      //     background: #ff3b30;
      //   }
      // }
    }
    .green {
      background: #0eb36e;
    }
    .orange {
      background: #fe9400;
    }
    .red {
      background: #ff3b30;
    }
    .current {
      background: #009aff;
    }
  }
}
</style>